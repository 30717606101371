<script setup>
import ButtonComponent from '@/components/ButtonComponent.vue'
import { computed } from 'vue'
import { toRefs } from 'vue'
import SvgIcon from '@/components/SvgIcon.vue'
import { getSrcSet } from '@/helpers/srcSetHelper.js'

const props = defineProps({
  title: String,
  description: String,
  models: Array,
  cta: String,
  ctaSm: String,
  ctaLink: String,
})

const { title, description, models, cta, ctaSm, ctaLink } = toRefs(props)

// On this file, there are some `style="display: none" class="lg:!block"` stuff:
// We set the style to none using `style` so that the browser doesn't download this on mobile before the Tailwind CSS is loaded
// The mobile versions of the images are hidden on mobile, but we add them in the picture element just in case some browsers want to load what's there even with display: none

const modelSrcs = computed(() =>
  models.value.map((model) => getSrcSet('models', model)),
)
</script>

<template>
  <div
    class="flex justify-center items-center bg-[#0A0D1E] rounded-[25px] py-[56px] px-4 lg:py-[30px] 2xl:p-0 border border-white border-opacity-5 lg:min-h-[345px] relative overflow-hidden"
    style="content-visibility: auto"
  >
    <div style="display: none" class="lg:!block">
      <div
        class="z-10 p-[0.6px] rounded-full absolute top-[62px] left-[88px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="modelSrcs[0].mobileAvif"
            type="image/avif"
            media="(max-width: 450px)"
          />
          <source
            :srcset="modelSrcs[0].mobileWebp"
            type="image/webp"
            media="(max-width: 450px)"
          />

          <source
            :srcset="modelSrcs[0].desktopAvif"
            type="image/avif"
            media="(min-width: 451px)"
          />
          <source
            :srcset="modelSrcs[0].desktopWebp"
            type="image/webp"
            media="(min-width: 451px)"
          />

          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="modelSrcs[0].default"
            :alt="models[0]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute rounded-full bottom-[59px] left-[50px] 2xl:left-[168px]"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="modelSrcs[1].mobileAvif"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[1].mobileWebp"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[1].desktopAvif"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="modelSrcs[1].desktopWebp"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="modelSrcs[1].default"
            :alt="models[1]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute bottom-[74px] right-[50px] 2xl:right-[163px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="modelSrcs[2].mobileAvif"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[2].mobileWebp"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[2].desktopAvif"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="modelSrcs[2].desktopWebp"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="modelSrcs[2].default"
            :alt="models[1]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
      <div
        class="z-10 p-[1px] absolute top-[72px] right-[55px] rounded-full"
        style="
          background: linear-gradient(
            55.67deg,
            #a455ff 13.36%,
            #7700ff 13.36%,
            rgba(128, 0, 255, 0) 66.41%
          );
        "
      >
        <picture>
          <source
            :srcset="modelSrcs[3].mobileAvif"
            type="image/avif"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[3].mobileWebp"
            type="image/webp"
            media="(max-width: 1023px)"
          />
          <source
            :srcset="modelSrcs[3].desktopAvif"
            type="image/avif"
            media="(min-width: 1024px)"
          />
          <source
            :srcset="modelSrcs[3].desktopWebp"
            type="image/webp"
            media="(min-width: 1024px)"
          />
          <img
            class="w-[60px] h-[60px] object-cover rounded-full select-none object-top"
            loading="lazy"
            :src="modelSrcs[3].default"
            :alt="models[3]"
            style="-webkit-user-drag: none"
          />
        </picture>
      </div>
    </div>
    <SvgIcon
      sprite="home"
      icon-id="particles"
      class="lg:!block absolute bottom-0 left-[50%] -translate-x-1/2 rounded-full select-none w-[735px] h-[225px]"
      style="display: none; -webkit-user-drag: none"
    />
    <SvgIcon
      sprite="home"
      icon-id="circles"
      class="lg:!block absolute top-[50%] w-full h-full translate-y-[-50%] select-none"
      style="display: none; -webkit-user-drag: none"
    />
    <div
      class="flex flex-col items-center gap-6 lg:gap-[36px] lg:w-[50%] text-center relative z-20"
    >
      <h2
        class="text-[25px] leading-[33px] lg:text-[35px] lg:leading-[47px]"
        v-html="title"
      ></h2>
      <p class="text-[16px]" v-html="description"></p>
      <ButtonComponent
        :to="ctaLink"
        class="flex items-center gap-[15px] py-[10px] pl-[11px] pr-[19px] text-[14px]"
      >
        <SvgIcon sprite="home" icon-id="magic-wand" class="w-6 h-6" />
        <span class="inline lg:hidden">{{ ctaSm }}</span>
        <span class="hidden lg:inline">{{ cta }}</span>
      </ButtonComponent>
    </div>
  </div>
</template>
