import App from '@/App.vue'
import { createApp, nextTick } from 'vue'

import store from '@/stores/index.js'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { init as sentryInit } from '@sentry/vue'

import getClientData from '@/helpers/getClientData.js'

import { createRouter, createWebHistory } from 'vue-router'

import enterReferrerDomainIgnores from '@/configs/enterReferrerDomainIgnores.js'
import discounts from 'common/lib/discount'
import { getPricing } from 'common/lib/pricing'

import { createHead } from '@unhead/vue'
import mixpanel from 'mixpanel-browser'

import queryString from 'query-string'

import getAffiliateTrackingParams from '@/helpers/getAffiliateTrackingParams.js'

import { usePopup } from '@/composables/usePopup.js'
import routes from './routes.js'

import '@/style.css'
import 'vue-loading-overlay/dist/css/index.css'
import 'vue-toast-notification/dist/theme-default.css'

function initApp(appElement, app, isHydrate) {
  if (
    import.meta.env.VITE_SENTRY_DSN &&
    import.meta.env.VITE_NODE_ENV == 'production'
  ) {
    sentryInit({
      environment: import.meta.env.VITE_ENV,
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [],
      beforeSend(event, hint) {
        if (
          hint &&
          hint.originalException &&
          hint.originalException.isAxiosError
        ) {
          if (
            hint.originalException.response &&
            hint.originalException.response.data
          ) {
            const contexts = {
              ...event.contexts,
            }
            contexts.errorResponse = {
              data: hint.originalException.response.data,
            }
            event.contexts = contexts
          }
        }
        return event
      },
    })
  }

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  const { openPopup } = usePopup()

  window.addEventListener('message', (e) => {
    const pricing = getPricing(import.meta.env.VITE_ENV !== 'dev')

    if (e.data.isPaymentError) {
      const iframeContainer = document.querySelector('.iframeContainer')
      iframeContainer.innerHTML = ''

      const form =
        document.querySelector('#paymentForm') ||
        document.querySelector('#savedCardData')
      form.classList.remove('hidden')
      form.classList.add('block')
      setTimeout(() => {
        store.commit('SET_PAYMENT_ERROR', e.data.message)
      }, 250)

      window.scrollTo(0, document.body.scrollHeight)
    }

    if (
      e.data.user ||
      typeof e.data.purchaseNumber === 'number' ||
      e.data.cardData
    ) {
      let updatedUser
      let params
      const user = store.state.user

      if (e.data.user) {
        const newUser = e.data.user

        // Delete cardBrand, cardExpirationDate and cardholderName if they are null so that they don't override the valid ones
        if (
          !newUser.cardBrand ||
          !newUser.cardExpirationDate ||
          !newUser.cardholderName
        ) {
          delete newUser.cardBrand
          delete newUser.cardExpirationDate
          delete newUser.cardholderName
        }

        updatedUser = {
          ...user,
          ...newUser,
        }

        params = { type: 'subscription' }
        params.subscriptionType =
          user.subscription === 0 ? 'initial' : 'upgrade'
      } else if (typeof e.data.purchaseNumber === 'number') {
        const lunaAmount = pricing.purchases[e.data.purchaseNumber].luna

        updatedUser = {
          ...user,
          luna: user.luna + lunaAmount,
        }
        params = { type: 'purchase' }
        params.luna = lunaAmount
      } else {
        updatedUser = {
          ...user,
          ...e.data.cardData,
          requireCvv: e.data.requireCvv,
        }
        params = { type: 'cardChange' }
      }

      store.commit('SET_USER', updatedUser)
      const stringifiedParams = queryString.stringify(params)
      router.push(`/purchase-complete?${stringifiedParams}`)
    }
  })

  app.use(store)

  // Use the router and axios with VueAxios
  app.use(router)
  const head = createHead()
  app.use(head)

  // TODO: Move over to fetch
  // There's not enough browser support yet. We'll need to change some of the interceptors too
  // axios.defaults.adapter = 'fetch'
  axios.defaults.baseURL = import.meta.env.VITE_BASE_URL

  axios.interceptors.response.use(
    (response) =>
      // Handle successful responses
      response,
    (error) => {
      // Modify the error message to include the URL
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        error.message = `Request to ${error.response.config.url} failed: ${error.message}`
      } else if (error.request) {
        // The request was made but no response was received
        error.message = `Request to ${error.config.url} failed: ${error.message}`
      }
      // Log the error or return it to be handled by the calling function
      console.error(error.message)
      return Promise.reject(error)
    },
  )

  axios.interceptors.request.use((config) => {
    if (!store.state.userBearerToken) {
      return config
    } else
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${store.state.userBearerToken}`,
        },
      }
  })

  axios.interceptors.response.use(
    (response) => {
      if (response.request.responseURL.startsWith(axios.defaults.baseURL)) {
        const responseBearerToken = response.headers['authorization-token']
        if (responseBearerToken !== store.state.userBearerToken) {
          if (responseBearerToken) {
            store.commit('SET_USER_BEARER_TOKEN', responseBearerToken)
          } else {
            store.commit('SET_USER_BEARER_TOKEN', null)
          }
        }
      }

      return response
    },
    (error) => {
      if (error.request.responseURL.startsWith(axios.defaults.baseURL)) {
        if (error.response && error.response.data.logout) {
          openPopup(error.response.data.title, error.response.data.content)
          store.commit('SET_USER', null)
          store.commit('SET_USER_BEARER_TOKEN', null)
          router.push('/')
        }
        if (error.response && error.response.status === 403) {
          store.commit('SET_USER', null)
          if (error.response.headers['banned-user'] === 'true') {
            store.commit('TOGGLE_BANNED', true)
            store.commit('SET_USER_BEARER_TOKEN', null)
            router.push('/')
          }
        }
        // did new status code here cuz we dont need to setUser to null if it is just warned
        if (error.response && error.response.status === 451) {
          if (error.response.data.bannableOffenses) {
            store.commit('TOGGLE_WARNED', true)
            store.commit(
              'SET_BANNABLE_OFFENSES',
              error.response.data.bannableOffenses,
            )
            store.commit('SET_WARN_REASON', error.response.data.reason)
          }
        }
      }

      return Promise.reject(error)
    },
  )

  axios.interceptors.request.use((config) => {
    const urls = [
      '/user/payment/initial',
      '/user/payment/recurring',
      '/user/payment/change-card',
      '/user/payment/check-status',
    ]

    if (!urls.includes(config.url)) return config

    const updatedBody = {
      ...config.data,
      clientData: getClientData(),
    }

    config.data = updatedBody
    return config
  })

  axios.interceptors.response.use((response) => {
    const urls = [
      '/user/payment/initial',
      '/user/payment/recurring',
      '/user/payment/change-card',
    ]

    if (!urls.includes(response.config?.url) || !response?.data?.redirect?.url)
      return response

    const paymentForm = document.querySelector('#paymentForm')
    const savedCardData = document.querySelector('#savedCardData')
    savedCardData?.classList?.add('hidden')
    paymentForm?.classList?.add('hidden')
    const container = document.querySelector('.iframeContainer')
    const iframe1 = document.createElement('iframe')
    iframe1.style.display = 'none'

    iframe1.addEventListener('load', (e) => {
      const iframeToBeAdded = document.getElementById('threeDSecureIframe')
      if (iframeToBeAdded) {
        return
      }

      const isTp = response.data.isTp

      const url = response.data.redirect.url
      let parameters = isTp
        ? response.data.redirectParams
        : response.data.redirect.parameters

      if (isTp) {
        parameters = Object.keys(parameters).map((key) => ({
          name: key,
          value: parameters[key],
        }))
      }

      const iframe = document.createElement('iframe')
      iframe.id = 'threeDSecureIframe'
      container.appendChild(iframe)

      const form = iframe.contentDocument.createElement('form')
      form.name = ''
      form.action = url
      form.method = 'POST'

      parameters.forEach((parameter) => {
        const input = iframe.contentDocument.createElement('input')
        input.type = 'hidden'
        input.name = parameter.name
        input.value = parameter.value
        form.appendChild(input)
      })

      iframe.contentDocument.body.appendChild(form)

      const script = iframe.contentDocument.createElement('script')
      script.innerHTML = `      
        function submitForm() {          
          document.forms[0].submit();
        };
        submitForm();
      `
      iframe.contentDocument.body.appendChild(script)
    })

    container.appendChild(iframe1)

    if (!response.data.redirect.preconditions) {
      return response
    }

    const preconditions = response.data.redirect.preconditions[0]

    const form1 = iframe1.contentDocument.createElement('form')
    form1.name = ''
    form1.action = preconditions.url
    form1.method = preconditions.method

    preconditions.parameters.forEach((parameter) => {
      const input = iframe1.contentDocument.createElement('input')
      input.type = 'hidden'
      input.name = parameter.name
      input.value = parameter.value
      form1.appendChild(input)
    })

    iframe1.contentDocument.body.appendChild(form1)

    const script1 = iframe1.contentDocument.createElement('script')
    script1.innerHTML = `
    function submitForm() {
      document.forms[0].submit()
    };
    submitForm()
    `
    iframe1.contentDocument.body.appendChild(script1)

    let scrollAmount
    const path = window.location.pathname
    const query = queryString.parse(window.location.search)
    const isMobile = window.innerWidth < 768

    switch (path) {
      case '/buy-luna':
        scrollAmount = isMobile ? 290 : 0
        break
      case '/settings':
        scrollAmount =
          query.subTab === 'subscription'
            ? isMobile
              ? 870
              : 320
            : isMobile
              ? 390
              : 280
        break
      default:
        scrollAmount = isMobile ? 305 : 340
        break
    }

    scrollTo(0, scrollAmount)

    return response
  })

  app.use(VueAxios, axios)
  app.provide('axios', app.config.globalProperties.axios)

  let firstRouteLoadFlag = true

  router.afterEach(async (to, from) => {
    if (to.path != from.path || firstRouteLoadFlag) {
      scrollTo(0, 0)
    }

    // This gets executed after the next route is mounted
    nextTick(async () => {
      // Need to load SVGs
      if ('__PRERENDER_INJECTED' in window) {
        return
      }

      if (window.spritesToBeLoaded?.size) {
        for (let sprite of window.spritesToBeLoaded) {
          if (window.spritesAlreadyLoaded?.has(sprite)) continue

          const svgText = await fetch(`/symbol/${sprite}.svg`).then((res) =>
            res.text(),
          )

          document.body.insertAdjacentHTML('beforeend', svgText)

          if (!window.spritesAlreadyLoaded) {
            window.spritesAlreadyLoaded = new Set([sprite])
          } else {
            window.spritesAlreadyLoaded.add(sprite)
          }
        }
      }
    })
  })

  router.beforeEach(async (to, from) => {
    if (to.path == from.path && !firstRouteLoadFlag) {
      return
    }

    if (
      (from.path === '/buy-luna' && to.path === '/purchase-complete') ||
      (from.path === '/purchase-complete' && to.path === '/buy-luna') ||
      (from.path === '/subscribe' && to.path === '/purchase-complete')
    )
      return
    store.commit('SET_ROUTES', {
      previous: from || null,
      current: to,
    })
    store.commit('SET_PURCHASE_REDIRECT_PATH', from.fullPath)

    const path = to.path
    let user = store.state.user
    const premadeModel = store.state.premadeModel

    if (firstRouteLoadFlag) {
      firstRouteLoadFlag = false

      if (to.query.userBearerToken) {
        store.commit('SET_USER_BEARER_TOKEN', to.query.userBearerToken)
      }

      if (store.state.userBearerToken) {
        try {
          user = await axios.get('/user/me').then((res) => res.data)
          store.commit('SET_FREE_GENERATED_IMAGES', user.freeGeneratedImages)

          // if (
          //   user?.subscription &&
          //   +new Date(user.createdAt) <=
          //     new Date(Date.UTC(2024, 11, 28, 10, 0, 0)) &&
          //   !localStorage.getItem('up1')
          // ) {
          //   store.commit('SET_GENERIC_POPUP', {
          //     open: true,
          //   })
          //   localStorage.setItem('up1', '1')
          // }
        } catch (error) {
          if (error?.response?.status != 403) {
            throw error
          } else {
            user = null
          }
        }
      } else {
        user = null
      }

      store.commit('SET_USER', user)

      const utmParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
      ]

      const utm = {}

      for (const utmParam of utmParams) {
        if (to.query[utmParam]) {
          utm[utmParam] = to.query[utmParam]
        }
      }

      mixpanel.track('enter_app', {
        ...utm,
      })

      if (Object.keys(utm).length) {
        store.commit('SET_UTM', utm)
      }

      if (to.query.userBearerToken) {
        delete to.query.userBearerToken

        if (!user.smsVerificationRequired) {
          return router.push(to) // We return cause router.push will retrigger `router.beforeEach`
        }
      }
    }
    // mixpanel.track_pageview()

    const loggedIn = Boolean(user)

    if (path !== '/register' && loggedIn && user.smsVerificationRequired) {
      return {
        path: '/register',
      }
    }

    if (
      path === '/login' ||
      path === '/forgot-password' ||
      path === '/reset-password'
    ) {
      if (loggedIn) {
        return {
          path: '/',
        }
      }
    } else if (path === '/register') {
      if (loggedIn && !user.smsVerificationRequired) {
        return {
          path: '/',
        }
      }
    } else if (
      path == '/settings' ||
      path == '/saved' ||
      path == '/subscribe' ||
      path == '/buy-luna'
    ) {
      if (!loggedIn) {
        return {
          path: '/register',
        }
      }
    }

    if (path.startsWith('/chat')) {
      if (!user?.models?.length && !premadeModel) {
        if (!loggedIn) {
          return {
            path: '/register',
          }
        }
        return {
          path: '/create-model',
          query: {
            from: 'home',
          },
        }
      }
    }
  })

  const params = new URLSearchParams(new URL(window.location.href).search)

  const discountCodeRef = params.get('discountCode') ?? store.state.discountCode

  if (discountCodeRef && discounts[discountCodeRef]) {
    function getEndDate(expiryDate, isReminder) {
      if (store.state.discountEndDate) {
        return store.state.discountEndDate
      }

      if (!expiryDate && !isReminder) {
        return null
      }

      if (isReminder) {
        return new Date().getTime() + 734000
      }

      return expiryDate.getTime()
    }

    const discTimer = discounts[discountCodeRef]?.timer
    const discExpiresAt = discounts[discountCodeRef]?.expiresAt
    const isReminder = discounts[discountCodeRef]?.isReminder
    const endDate = getEndDate(discTimer || discExpiresAt, isReminder)
    const now = new Date().getTime()

    if (endDate && endDate - now > 0) {
      store.commit('SET_DISCOUNT_CODE', {
        code: discountCodeRef,
        endDate,
      })
      store.commit('SET_DISCOUNT_POPUP', {
        open: true,
      })
    } else if (endDate && endDate - now <= 0) {
      store.commit('SET_DISCOUNT_CODE', {})
      store.commit('SET_DISCOUNT_POPUP', {
        open: false,
      })
    }

    if (!endDate) {
      store.commit('SET_DISCOUNT_CODE', { code: discountCodeRef })
    }
  }

  window.spritesToBeLoaded = new Set()

  app.mount(appElement, isHydrate)

  // We use this query param for cross-site tracking with affliates who
  // use a different domain than we do. It allows us to track the full
  // user journey
  const mixpanelDistinctIdParam = params.get('mixpanelDistinctId')

  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: import.meta.env.VITE_ENV === 'dev',
    track_pageview: false,
    persistence: 'localStorage',
    api_host:
      import.meta.env.VITE_ENV === 'dev'
        ? `https://dev.herahaven.com/api/t`
        : '/api/t',
    ip: true,
    track_marketing: false,
    ignore_dnt: true,
  })

  if (mixpanelDistinctIdParam) {
    mixpanel.identify(mixpanelDistinctIdParam)
  }

  // if (import.meta.env.VITE_TIKTOK_PIXEL_CODE) {
  //   function getTiktokIds() {
  //     const tiktokCookieId = getCookie('_ttp')
  //     const tiktokClickId = getCookie('ttclid')

  //     store.commit('SET_ANALYTICS_VARIABLE', {
  //       key: 'tiktokCookieId',
  //       val: tiktokCookieId,
  //     })
  //     store.commit('SET_ANALYTICS_VARIABLE', {
  //       key: 'tiktokClickId',
  //       val: tiktokClickId,
  //     })

  //     if (tiktokClickId) {
  //       store.commit('SET_ANALYTICS_VARIABLE', {
  //         key: 'lastSource',
  //         val: 'tiktok',
  //       })
  //     }
  //   }

  //   getTiktokIds()

  //   // Hack because we can't guarantee that the Tiktok Pixel has loaded
  //   // since it's loaded asynchronously
  //   // Unfortunately there is no way for us to be notified when it's actually loaded
  //   setTimeout(getTiktokIds, 2000)
  // }

  // const exoParam = params.get('exo_id')

  // if (exoParam) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'exoId',
  //     val: exoParam,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'exo',
  //   })

  // if (import.meta.env.VITE_EXOCLICK_VISIT_GOAL_ID) {
  //   loadScript('https://a.exoclick.com/tag_gen.js', {
  //     goal: import.meta.env.VITE_EXOCLICK_VISIT_GOAL_ID,
  //   })
  // }
  // }

  // const jadsBan = params.get('jads_ban')
  // const jadsPop = params.get('jads_pop')

  // if (jadsBan) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'jadsBan',
  //     val: jadsBan,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'jucyAds',
  //   })
  // }
  // if (jadsPop) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'jadsPop',
  //     val: jadsPop,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'jucyAds',
  //   })
  // }

  // const caduParam = params.get('cadu_visitor_id')

  // if (caduParam) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'caduVisitorId',
  //     val: caduParam,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'cadu',
  //   })
  // }

  const explaParam = params.get('expla_id')

  if (explaParam) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'explaId',
      val: explaParam,
    })

    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'lastSource',
      val: 'expla',
    })
  }

  const crakParam = params.get('crak_tx_id')

  if (crakParam) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'crakTxId',
      val: crakParam,
    })

    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'lastSource',
      val: 'crak',
    })
  }

  // if (import.meta.env.VITE_VOLUUM_PARAM) {
  //   const voluumParam = params.get(import.meta.env.VITE_VOLUUM_PARAM)

  //   if (voluumParam) {
  //     store.commit('SET_ANALYTICS_VARIABLE', {
  //       key: 'voluumCid',
  //       val: voluumParam,
  //     })
  //   }
  // }

  const affiliateParams = getAffiliateTrackingParams()

  const affiliateRefId = affiliateParams['ref_id']
  const affiliateCampaignId = affiliateParams['campaign_id']
  const affiliateSub1 = affiliateParams['sub1']
  const affiliateSub2 = affiliateParams['sub2']
  const affiliateSub3 = affiliateParams['sub3']
  // const affiliateSub4 = affiliateParams['sub4']
  // const affiliateSub5 = affiliateParams['sub5']
  // const affiliateSub6 = affiliateParams['sub6']
  // const affiliateSub7 = affiliateParams['sub7']
  // const affiliateSub8 = affiliateParams['sub8']

  if (affiliateRefId) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'affiliateRefId',
      val: affiliateRefId,
    })

    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'lastSource',
      val: 'affiliate',
    })

    if (affiliateCampaignId) {
      store.commit('SET_ANALYTICS_VARIABLE', {
        key: 'affiliateCampaignId',
        val: affiliateCampaignId,
      })
    }

    if (affiliateSub1) {
      store.commit('SET_ANALYTICS_VARIABLE', {
        key: 'affiliateSub1',
        val: affiliateSub1,
      })
    }

    if (affiliateSub2) {
      store.commit('SET_ANALYTICS_VARIABLE', {
        key: 'affiliateSub2',
        val: affiliateSub2,
      })
    }

    if (affiliateSub3) {
      store.commit('SET_ANALYTICS_VARIABLE', {
        key: 'affiliateSub3',
        val: affiliateSub3,
      })
    }

    // if (affiliateSub4) {
    //   store.commit('SET_ANALYTICS_VARIABLE', {
    //     key: 'affiliateSub4',
    //     val: affiliateSub4,
    //   })
    // }

    // if (affiliateSub5) {
    //   store.commit('SET_ANALYTICS_VARIABLE', {
    //     key: 'affiliateSub5',
    //     val: affiliateSub5,
    //   })
    // }

    // if (affiliateSub6) {
    //   store.commit('SET_ANALYTICS_VARIABLE', {
    //     key: 'affiliateSub6',
    //     val: affiliateSub6,
    //   })
    // }

    // if (affiliateSub7) {
    //   store.commit('SET_ANALYTICS_VARIABLE', {
    //     key: 'affiliateSub7',
    //     val: affiliateSub7,
    //   })
    // }

    // if (affiliateSub8) {
    //   store.commit('SET_ANALYTICS_VARIABLE', {
    //     key: 'affiliateSub8',
    //     val: affiliateSub8,
    //   })
    // }
  }

  const referrerDomain = !document.referrer
    ? null
    : new URL(document.referrer).hostname

  if (referrerDomain && !enterReferrerDomainIgnores.includes(referrerDomain)) {
    store.commit('SET_ANALYTICS_VARIABLE', {
      key: 'enterReferrer',
      val: document.referrer,
    })
  }

  axios.interceptors.request.use((config) => {
    const analytics = store.state.analytics

    // config.headers['GA-Click-ID'] = analytics.gaClickId
    // config.headers['GA-Gbraid'] = analytics.gaGbraid
    // config.headers['GA-Wbraid'] = analytics.gaWbraid
    // config.headers['Facebook-Click-ID'] = analytics.facebookClickId
    // config.headers['TikTok-Cookie-ID'] = analytics.tiktokCookieId
    // config.headers['TikTok-Click-ID'] = analytics.tiktokClickId
    // config.headers['Exo-ID'] = analytics.exoId
    // config.headers['Jads-Ban'] = analytics.jadsBan
    // config.headers['Jads-Pop'] = analytics.jadsPop
    // config.headers['Cadu-Visitor-ID'] = analytics.caduVisitorId
    config.headers['Expla-ID'] = analytics.explaId
    config.headers['Crak-TX-ID'] = analytics.crakTxId
    // config.headers['Voluum-Click-ID'] = analytics.voluumParam
    config.headers['Enter-Referrer'] = analytics.enterReferrer

    // config.headers['Partner-ID'] = analytics.partnerId
    // config.headers['Partner-GA-Click-ID'] = analytics.partnerGaClickId
    // config.headers['Partner-GA-Gbraid'] = analytics.partnerGaGbraid
    // config.headers['Partner-GA-Wbraid'] = analytics.partnerGaWbraid
    // config.headers['Partner-Facebook-Browser-ID'] =
    //   analytics.partnerFacebookBrowserId
    // config.headers['Partner-Facebook-Click-ID'] = analytics.partnerFacebookClickId

    config.headers['Affiliate-Ref-ID'] = analytics.affiliateRefId
    config.headers['Affiliate-Campaign-ID'] = analytics.affiliateCampaignId
    config.headers['Affiliate-Sub1'] = analytics.affiliateSub1
    config.headers['Affiliate-Sub2'] = analytics.affiliateSub2
    config.headers['Affiliate-Sub3'] = analytics.affiliateSub3
    // config.headers['Affiliate-Sub4'] = analytics.affiliateSub4
    // config.headers['Affiliate-Sub5'] = analytics.affiliateSub5
    // config.headers['Affiliate-Sub6'] = analytics.affiliateSub6
    // config.headers['Affiliate-Sub7'] = analytics.affiliateSub7
    // config.headers['Affiliate-Sub8'] = analytics.affiliateSub8

    config.headers['Last-Source'] = analytics.lastSource

    return config
  })

  // router.isReady().then(() => {
  // const queryParams = router.currentRoute.value.query

  // const gclid = queryParams.gclid
  // const gbraid = queryParams.gbraid
  // const wbraid = queryParams.wbraid

  // if (gclid || gbraid || wbraid) {
  //   store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaClickId', val: gclid })
  //   store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaGbraid', val: gbraid })
  //   store.commit('SET_ANALYTICS_VARIABLE', { key: 'gaWbraid', val: wbraid })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'google',
  //   })
  // }

  // const partnerId = queryParams.partner_id

  // if (partnerId) {
  //   store.commit('SET_ANALYTICS_VARIABLE', { key: 'partnerId', val: partnerId })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'partner',
  //   })
  // }

  // const partnerGclid = queryParams.partner_gclid
  // const partnerGbraid = queryParams.partner_gbraid
  // const partnerWbraid = queryParams.partner_wbraid

  // if (partnerGclid || partnerGbraid || partnerWbraid) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'partnerGaClickId',
  //     val: partnerGclid,
  //   })
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'partnerGaGbraid',
  //     val: partnerGbraid,
  //   })
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'partnerGaWbraid',
  //     val: partnerWbraid,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'partner_google',
  //   })
  // }

  // const partnerFacebookBrowserId = queryParams.partner_fbp
  // const partnerFacebookClickId = queryParams.partner_fbc

  // if (partnerFacebookBrowserId || partnerFacebookClickId) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'partnerFacebookBrowserId',
  //     val: partnerFacebookBrowserId,
  //   })
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'partnerFacebookClickId',
  //     val: partnerFacebookClickId,
  //   })

  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'lastSource',
  //     val: 'partner_facebook',
  //   })
  // }

  // const facebookClickId = queryParams.fbclid

  // if (facebookClickId) {
  //   store.commit('SET_ANALYTICS_VARIABLE', {
  //     key: 'facebookClickId',
  //     val: facebookClickId,
  //   })

  //   if (facebookClickId) {
  //     store.commit('SET_ANALYTICS_VARIABLE', {
  //       key: 'lastSource',
  //       val: 'facebook',
  //     })
  //   }
  // }
  // })

  // TODO: Need to find a way to readd this without spamming our analytics
  // document.addEventListener('visibilitychange', function () {
  //   if (document.hidden) {
  //     mixpanel.track('page_exit')
  //     hj('event', 'page_exit')
  //   } else {
  //     mixpanel.track('page_enter')
  //     hj('event', 'page_enter')
  //   }
  // })

  window.addEventListener('load', () => {
    // Disable right clicking on production so they can't download images
    // and spam the debugger to annoy users opening the console
    if (import.meta.env.VITE_ENV === 'production') {
      document.addEventListener('contextmenu', (event) =>
        event.preventDefault(),
      )

      setInterval(() => {
        debugger
      }, 3000)
    }

    const recaptchaScript = document.createElement('script')
    recaptchaScript.src = `https://www.google.com/recaptcha/enterprise.js?render=${import.meta.env.VITE_RECAPTCHA_KEY}`
    recaptchaScript.async = true
    recaptchaScript.fetchPriority = 'low'
    recaptchaScript.dataset.prerenderRemove = 'true'
    document.body.appendChild(recaptchaScript)

    const affiliateScript = document.createElement('script')
    affiliateScript.innerHTML = `function getUrlParameter(){return window.location.hash}var trParam=getUrlParameter();if(trParam.startsWith("#tr&")){var r,a="https://partners.herahaven.com/click.jpg?"+trParam.substring(4),t=document.createElement("img");t.src=a,t.style.display="none",document.head.appendChild(t)}`
    affiliateScript.dataset.prerenderRemove = 'true'
    document.body.appendChild(affiliateScript)
  })
}

const appElement = document.getElementById('app')

const isPrerendering = '__PRERENDER_INJECTED' in window

if (!isPrerendering) {
  const app = createApp(App)

  const isPrerendered = appElement.dataset.prerendered == 'true'

  initApp(appElement, app, isPrerendered)
} else {
  appElement.dataset.prerendered = 'true'
  import('vue').then(({ createSSRApp }) => {
    const app = createSSRApp(App)
    initApp(appElement, app, false)
  })
}

axios.interceptors.request.use(
  (config) => {
    const distinctId = mixpanel.get_distinct_id()
    if (distinctId) {
      config.headers['mixpanel-distinct-id'] = distinctId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
