<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ButtonComponent from '@/components/ButtonComponent.vue'
import NavigationListItem from '@/components/Navigation/NavigationListItem.vue'
import useNavigationMenu from '@/composables/useNavigationMenu'
import SvgIcon from '@/components/SvgIcon.vue'

const $store = useStore()
const $route = useRoute()

const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)
const isHomePage = computed(() =>
  ['/', '/ai-boyfriend', '/ai-hentai'].includes($route.path),
)
const user = computed(() => $store.state.user)
const unreadUpdates = computed(() =>
  $store.state.user ? $store.state.user.unreadUpdates : 0,
)

const {
  goHome,
  goToGenerator,
  goToChat,
  goToSaved,
  goToRater,
  goToUpdates,
  goToSettings,
  logout,
} = useNavigationMenu()

function toggleMobileMenu(value) {
  $store.commit('SET_MOBILE_MENU_OPEN', {
    open: value,
  })
}
</script>

<template>
  <div
    v-show="mobileMenuOpen"
    class="w-full fixed h-full flex flex-col px-[36px] py-[33px] bg-[#070917] overflow-hidden"
    style="z-index: 99999"
  >
    <ButtonComponent
      variant="close"
      class="p-3 absolute top-[15px] right-[36px] border-none"
      @click="toggleMobileMenu(false)"
    >
      <SvgIcon sprite="home" icon-id="x-close" class="w-[24px] h-[24px]" />
    </ButtonComponent>
    <div class="mb-[87px]">
      <router-link to="/" @click.prevent.stop.capture="goHome(true)">
        <SvgIcon
          sprite="home"
          icon-id="logo"
          class="w-[130px] aspect-[865/120]"
        />
      </router-link>
    </div>
    <nav class="flex flex-col grow space-between rounded-t-[15px]">
      <div role="list" class="flex flex-1 flex-col gap-y-[15px]">
        <NavigationListItem
          :to="isHomePage ? $route.path : '/'"
          label="Explore"
          icon-sprite="home"
          icon-id="compass"
          gray-icon-sprite="main"
          gray-icon-id="compass-grey"
          :active="isHomePage"
          :hideLabel="false"
          @click.prevent.stop.capture="goHome(false)"
        />
        <NavigationListItem
          to="/nsfw-ai-image-generator"
          label="Generator"
          icon-sprite="generator"
          icon-id="generator"
          gray-icon-sprite="home"
          gray-icon-id="generator-grey"
          :hideLabel="false"
          :active="$route.path === '/nsfw-ai-image-generator'"
          @click.prevent.stop.capture="goToGenerator"
        />
        <NavigationListItem
          to="/chat"
          label="Chat"
          icon-sprite="main"
          icon-id="chat"
          gray-icon-sprite="home"
          gray-icon-id="chat-grey"
          :hideLabel="false"
          :active="$route.path === '/chat'"
          @click.prevent.stop.capture="goToChat"
        />
        <NavigationListItem
          to="/saved"
          label="Saved"
          icon-sprite="main"
          icon-id="saved"
          gray-icon-sprite="home"
          gray-icon-id="saved-grey"
          :hideLabel="false"
          :active="$route.path === '/saved'"
          @click.prevent.stop.capture="goToSaved"
        />
        <NavigationListItem
          to="/rate-my-cock"
          label="Rater"
          icon-sprite="rater"
          sprite="rater"
          icon-id="eggplant-line"
          gray-icon-sprite="home"
          gray-icon-id="eggplant-line-grey"
          :active="$route.path === '/rate-my-cock'"
          :hideLabel="false"
          @click.prevent.stop.capture="goToRater"
        />
        <NavigationListItem
          to="/updates"
          label="Updates"
          icon-sprite="main"
          icon-id="updates"
          gray-icon-sprite="home"
          gray-icon-id="updates-gray"
          :hideLabel="false"
          :active="$route.path === '/updates'"
          @click.prevent.stop.capture="goToUpdates"
          :unreadUpdates="unreadUpdates"
        />
        <NavigationListItem
          to="/settings"
          label="Settings"
          icon-sprite="main"
          icon-id="settings"
          gray-icon-sprite="home"
          gray-icon-id="settings-grey"
          :hideLabel="false"
          :active="$route.path === '/settings'"
          @click.prevent.stop.capture="goToSettings"
        />
      </div>
      <div class="flex flex-col pt-[15px]">
        <NavigationListItem
          v-if="user"
          to="/"
          label="Logout"
          icon-sprite="main"
          icon-id="logout"
          gray-icon-sprite="main"
          gray-icon-id="logout-grey"
          :active="false"
          :hideLabel="false"
          @click.prevent.stop.capture="logout"
        />
      </div>
    </nav>
  </div>
</template>
