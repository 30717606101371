<script setup>
import { inject, onMounted } from 'vue'
import { useHead } from '@unhead/vue'
import { useRoute } from 'vue-router'

const $axios = inject('axios')
const $route = useRoute()

useHead({
  meta: [{ name: 'robots', content: 'noindex' }],
})

const checkStatus = async () => {
  try {
    const { data } = await $axios.post('/user/payment/check-status', {
      id: $route.query.id || $route.query.instanceId,
      isTp: Boolean($route.query.instanceId),
    })

    const messageData = {
      ...(data.user && { user: data.user }),
      ...(typeof data.purchaseNumber === 'number' && {
        purchaseNumber: data.purchaseNumber,
      }),
      ...(data.cardData && {
        cardData: data.cardData,
        requireCvv: data.requireCvv,
      }),
    }

    window.parent.postMessage(messageData)
  } catch (e) {
    const message = e.response?.data?.message
    window.parent.postMessage({
      message: message || 'Unable to perform action. Please try again later.',
      isPaymentError: true,
    })
    throw e
  }
}

onMounted(checkStatus)
</script>

<template></template>
