import store from '@/stores/index.js'
import Home from '@/views/Home/Home.vue'
import axios from 'axios'
import premadeModels from '@/assets/data/premadeBasicInfo.js'
import isCountryUs from '@/helpers/isCountryUs.js'
import ThreeDSecureRedirect from '@/views/ThreeDSecureRedirect.vue'
import aiGirlfriendRoute from '@/configs/routes/ai-girlfriend.js'
import aiHentaiRoute from '@/configs/routes/ai-hentai.js'
import aiBoyfriendRoute from '@/configs/routes/ai-boyfriend.js'
import nsfwAiImageGeneratorRoute from '@/configs/routes/generator/nsfw-ai-image.generator.js'

const routes = [
  {
    path: '/',
    component: Home,
    props: aiGirlfriendRoute,
    meta: {
      hasSideBar: true,
      homePageRoute: aiGirlfriendRoute.type,
    },
  },
  {
    path: '/ai-boyfriend',
    component: Home,
    props: aiBoyfriendRoute,
    meta: {
      hasSideBar: true,
      homePageRoute: aiBoyfriendRoute.type,
    },
  },
  {
    path: '/ai-hentai',
    component: Home,
    props: aiHentaiRoute,
    meta: {
      hasSideBar: true,
      homePageRoute: aiHentaiRoute.type,
    },
  },
  ...[
    'adult-ai-chat',
    'ai-anime',
    'ai-chatbot-18-plus',
    'ai-companion',
    'ai-flirt-chat',
    'ai-jerk-off',
    'ai-porn-chat',
    'ai-sex-chat',
    'ai-sexting',
    'ai-xxx',
    'candy-ai',
    'dirty-talk-ai',
    'erotic-ai',
    'sexy-ai',
    'spicy-ai',
    'horny-ai',
    'nsfw-ai-chat',
    'fantasy-ai',
    'uncensored-ai',
    'roleplay-ai',
    'nsfw-chatgpt',
    'nsfw-character-ai',
    'explicit-ai',
  ].map((routeName) => ({
    path: `/${routeName}`,
    component: Home,
    async beforeEnter(to) {
      to.meta.data = await import(`../configs/routes/${routeName}.js`).then(
        (module) => module.default,
      )
      to.meta.homePageRoute = to.meta.data.type
    },
    props(to) {
      return to.meta.data
    },
    meta: {
      hasSideBar: true,
    },
  })),
  {
    path: '/anime-ai-girlfriend',
    redirect: '/ai-hentai',
  },
  {
    path: '/anime-ai-boyfriend',
    redirect: '/ai-hentai',
  },
  {
    path: '/login',
    component: () => import('@/views/auth/Login.vue'),
  },
  {
    path: '/register',
    component: () => import('@/views/auth/Register.vue'),
  },
  {
    path: '/complete-register',
    component: () => import('@/views/auth/CompleteRegister.vue'),
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  { path: '/rater', redirect: '/rate-my-cock' },
  {
    path: '/rate-my-cock',
    component: () => import('@/views/Rater.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/ai-girlfriend/:modelName',
    component: () => import('@/views/ModelDetails.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.style == 'realistic' &&
            premadeModel.model.gender == 'female' &&
            !premadeModel.hidden,
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  {
    path: '/ai-hentai/:modelName',
    component: () => import('@/views/ModelDetails.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.style == 'anime' &&
            !premadeModel.hidden,
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  {
    path: '/ai-boyfriend/:modelName',
    component: () => import('@/views/ModelDetails.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (
        !premadeModels.some(
          (premadeModel) =>
            premadeModel.model.name.toLowerCase() === to.params.modelName &&
            premadeModel.model.style == 'realistic' &&
            premadeModel.model.gender == 'male' &&
            !premadeModel.hidden,
        )
      ) {
        return next({
          name: 'NotFound',
          params: { pathMatch: to.path.split('/').slice(1) },
          query: to.query,
          hash: to.hash,
        })
      }

      return next()
    },
  },
  { path: '/image-generator', redirect: '/nsfw-ai-image-generator' },
  { path: '/generator', redirect: '/nsfw-ai-image-generator' },
  {
    path: '/nsfw-ai-image-generator',
    component: () => import('@/views/Generator.vue'),
    meta: {
      hasSideBar: true,
    },
    props: nsfwAiImageGeneratorRoute,
  },
  ...[
    'adult-ai-image-generator',
    'ai-anime-generator',
    'ai-hentai-generator',
    'ai-image-generator-18-plus',
    'ai-nude-generator',
    'ai-porn-generator',
    'ai-sex-generator',
    'erotic-ai-image-generator',
    'explicit-ai-image-generator',
    'lewd-ai-generator',
    'nsfw-ai-prompt-generator',
    'r34-ai-image-generator',
    'sexy-ai-generator',
    'uncensored-ai-image-generator',
    'unfiltered-ai-image-generator',
  ].map((routeName) => ({
    path: `/${routeName}`,
    component: () => import('@/views/Generator.vue'),
    async beforeEnter(to) {
      const routeProps = await import(
        `../configs/routes/generator/${routeName}.js`
      ).then((module) => module.default)
      store.commit('SET_GENERATOR_ROUTE_PROPS', routeProps)
      to.meta.data = routeProps
    },
    props(to) {
      const routeProps = store.state.generatorRouteProps
      to.meta.data = routeProps
      return to.meta.data
    },
    meta: {
      hasSideBar: true,
    },
  })),
  ...['ai-milf', 'ebony-ai', 'big-tits-ai'].map((routeName) => ({
    path: `/categories/${routeName}`,
    component: () => import('@/views/Category.vue'),
    async beforeEnter(to) {
      to.meta.data = await import(
        `../configs/routes/categories/${routeName}.js`
      ).then((module) => module.default)
    },
    props(to) {
      return to.meta.data
    },
    meta: {
      hasSideBar: true,
    },
  })),
  {
    path: '/categories',
    component: () => import('@/views/Categories.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
  },
  { path: '/app', redirect: '/chat' },
  {
    path: '/chat',
    component: () => import('@/views/Chat/Chat.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/settings',
    component: () => import('@/views/Account/Account.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/saved',
    component: () => import('@/views/Saved.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/subscribe',
    component: () => import('@/views/Subscribe.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user?.subscription) {
        next({ path: '/settings', query: { tab: 'subscription' } })
      } else next()
    },
  },
  {
    path: '/create-model',
    component: () => import('@/views/CreateModel/CreateModel.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const { gender, style, ...otherQueryParams } = to.query

      if (gender === 'female' || gender === 'male') {
        if (style === 'realistic' || style === 'anime') {
          return next({
            path: '/create-model/2',
            query: { ...otherQueryParams, gender, style },
          })
        }

        return next({
          path: '/create-model/1',
          query: { ...otherQueryParams, gender },
        })
      }

      return next({ path: '/create-model/0', query: otherQueryParams })
    },
  },
  {
    path: '/create-girlfriend',
    component: () => import('@/views/CreateModel/CreateModel.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-boyfriend',
    component: () => import('@/views/CreateModel/CreateModel.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/create-model/:n',
    component: () => import('@/views/CreateModel/CreateModel.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      // Check if 'n' is an integer
      const n = parseInt(to.params.n, 10)
      if (isNaN(n)) {
        // If 'n' is not an integer, cancel the navigation
        next(false)
      } else {
        // If 'n' is an integer, add it as an integer to route params and proceed
        to.params.n = n
        next()
      }
    },
  },
  {
    path: '/view-model',
    component: () => import('@/views/ViewModel.vue'),
    meta: {
      hasSideBar: true,
    },
    beforeEnter: (to, from, next) => {
      const pendingModel = store.state.pendingModel

      if (!pendingModel) {
        return next({ path: '/create-model' })
      }

      return next()
    },
  },
  {
    path: '/buy-luna',
    component: () => import('@/views/BuyLuna/BuyLuna.vue'),
  },
  {
    path: '/purchase-complete',
    component: () => import('@/views/PurchaseComplete.vue'),
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/threeds-redirect',
    component: ThreeDSecureRedirect,
    beforeEnter: (to, from, next) => {
      if (Object.keys(to.query).length === 0) {
        next({ path: '/' })
      } else next()
    },
  },
  {
    path: '/privacy-policy',
    component: {
      template:
        '<div>Redirecting to the appropriate privacy policy page...</div>',
    },
    meta: {
      hasSideBar: true,
    },
    beforeEnter: async (to, from, next) => {
      let country = ''

      if (store.state.country) {
        country = store.state.country
      } else {
        if (store.state.user) {
          country = store.state.user?.country
        } else {
          const data = await axios
            .get('/location/country')
            .then((res) => res.data)
          country = data.countryCode
          countryState = data.state
          store.commit('SET_COUNTRY', { country, countryState })
        }
      }

      const isUs = isCountryUs(country)

      if (country && isUs) {
        next({ path: '/privacy-policy-usa' })
      }
      next({ path: '/privacy-policy-row' })
    },
  },
  {
    path: '/privacy-policy-usa',
    component: () => import('@/views/policies/PrivacyPolicyUSA.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/privacy-policy-row',
    component: () => import('@/views/policies/PrivacyPolicyROW.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/cancellation-policy',
    component: () => import('@/views/policies/CancellationPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service-eea',
    component: () => import('@/views/policies/TermsOfServiceEEA.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service-row',
    component: () => import('@/views/policies/TermsOfServiceROW.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/terms-of-service',
    // for some reason beforeEneter guard is not working without component part here, so I added a dummy component.
    component: {
      template:
        '<div>Redirecting to the appropriate terms of service page...</div>',
    },
    meta: {
      hasSideBar: true,
    },
    beforeEnter: async (to, from, next) => {
      let country = ''
      let eeaPlusCountries

      async function fetchEEACountries() {
        eeaPlusCountries = await import(
          '@/assets/data/eeaPlusCountries.json'
        ).then((res) => res.default)
      }

      if (store.state.country) {
        country = store.state.country
        await fetchEEACountries()
      } else {
        if (store.state.user) {
          country = store.state.user?.country
          await fetchEEACountries()
        } else {
          let resp
          ;[resp, eeaPlusCountries] = await Promise.all([
            axios.get(import.meta.env.VITE_BASE_URL + '/location/country'),
            fetchEEACountries(),
          ])
          country = resp.data.countryCode
          store.commit('SET_COUNTRY', { country })
        }
      }

      function isCountryInEEA(countryName) {
        return eeaPlusCountries.some(
          (country) => country.code.toLowerCase() === countryName.toLowerCase(),
        )
      }

      if (country && isCountryInEEA(country)) {
        next({ path: '/terms-of-service-eea' })
      }
      next({ path: '/terms-of-service-row' })
    },
  },
  {
    path: '/complaint-policy',
    component: () => import('@/views/policies/ComplaintPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/content-removal-policy',
    component: () => import('@/views/policies/ContentRemovalPolicy.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/affiliate',
    component: () => import('@/views/Affiliate/Affiliate.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/updates',
    component: () => import('@/views/Updates.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/verification-status',
    component: () => import('@/views/Verification.vue'),
    meta: {
      hasSideBar: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
]

export default routes
