<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import isCountryUs from '@/helpers/isCountryUs.js'
import SvgIcon from '@/components/SvgIcon.vue'

const $store = useStore()

const currentCountry = computed(() => $store.state.country)

const corpName = computed(() => $store.state.companyDetails.corpName)
const corpAddress = computed(() => $store.state.companyDetails.corpAddress)

const supportEmail = ref('support_email_placeholder')

// Bypass DigitalOcean's email-decode script
onMounted(() => {
  const isPrerendering = '__PRERENDER_INJECTED' in window

  if (!isPrerendering) {
    supportEmail.value = import.meta.env.VITE_SUPPORT_EMAIL
  }
})

const twitterHandle = import.meta.env.VITE_TWITTER_HANDLE
const instagramHandle = import.meta.env.VITE_INSTAGRAM_HANDLE
const facebookHandle = import.meta.env.VITE_FACEBOOK_HANDLE
const redirectDomainName = import.meta.env.VITE_REDIRECT_DOMAIN_NAME
</script>

<template>
  <footer
    class="flex flex-col lg:gap-[30px] mt-[100px] lg:mt-[140px] lg:pb-[80px]"
    style="content-visibility: auto"
  >
    <div
      class="flex flex-col gap-[25px] lg:gap-0 lg:flex-row justify-between items-center mb-[50px] lg:mb-0"
    >
      <SvgIcon
        sprite="home"
        icon-id="logo"
        class="w-[190px] aspect-[865/120]"
      />
      <div class="flex gap-4 justify-center md:justify-start">
        <a
          target="_blank"
          rel="noopener"
          :href="`https://www.facebook.com/${facebookHandle}`"
          class="bg-[#131331] p-[15px] px-[19px] rounded-full"
        >
          <SvgIcon sprite="home" icon-id="facebook" class="w-[9px] h-[17px]" />
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://instagram.com/${instagramHandle}`"
          class="bg-[#131331] p-[15px] rounded-full"
        >
          <SvgIcon
            sprite="home"
            icon-id="instagram"
            class="w-[18px] h-[18px]"
          />
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://twitter.com/${twitterHandle}`"
          class="bg-[#131331] p-[15px] rounded-full"
        >
          <SvgIcon sprite="home" icon-id="x" class="w-[18px] h-[18px]" />
        </a>
      </div>
    </div>
    <div
      class="flex flex-col lg:flex-row items-center justify-between w-full md:text-left border border-white border-opacity-5 rounded-[15px] py-[35px] px-[30px]"
      style="
        background: radial-gradient(
          123.96% 123.96% at 18.89% 102.14%,
          rgba(76, 12, 81, 0.2) 0%,
          rgba(37, 6, 38, 0) 100%
        );
      "
    >
      <div
        class="text-[14px] text-center lg:text-left lg:flex-1 2xl:flex-[unset]"
      >
        Copyright © 2025, {{ corpName }}. All rights reserved.<br />
        {{ corpAddress }}
        <br /><br />
        All content in this website is
        <span class="text-[#CC47FF]">AI-generated.</span>
      </div>
      <div class="text-[14px]">
        <div
          class="flex flex-col items-center gap-[22px] mb-[38px] mt-[41px] lg:grid lg:grid-cols-[1fr_auto_1fr] lg:justify-items-end lg:gap-x-[22px] lg:gap-y-0 lg:mt-0 lg:mb-[23px]"
        >
          <router-link to="/terms-of-service">Terms of Service</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/cancellation-policy"
            >Cancellation Policy</router-link
          >
          <div class="hidden lg:block"></div>
          <router-link to="/complaint-policy">Complaint Policy</router-link>
          <router-link to="/content-removal-policy"
            >Content Removal Policy</router-link
          >
        </div>
        <div class="flex justify-end gap-2">
          <span>{{ supportEmail }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <div
        class="h-8 flex gap-4 my-[25px] lg:my-0 justify-center md:justify-start"
      >
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <SvgIcon sprite="home" icon-id="card-brand-visa" class="w-[80%]" />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <SvgIcon
            sprite="home"
            icon-id="card-brand-mastercard"
            class="w-[70%]"
          />
        </div>
        <div v-if="currentCountry">
          <div v-if="!isCountryUs(currentCountry)" class="flex gap-4">
            <div
              class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
            >
              <SvgIcon sprite="home" icon-id="vbv" class="w-[80%]" />
            </div>
            <div
              class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
            >
              <SvgIcon sprite="home" icon-id="ms" class="w-[80%]" />
            </div>
          </div>
          <div
            v-else
            class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
          >
            <SvgIcon
              sprite="home"
              icon-id="card-brand-discover"
              class="w-[80%]"
            />
          </div>
        </div>
      </div>
      <div
        class="flex gap-[26px] mb-[30px] lg:mb-0 flex-wrap justify-center lg:justify-normal gap-y-4"
      >
        <a class="text-[14px]" href="/blog">Blog</a>
        <router-link class="block text-[14px]" to="/categories"
          >Categories</router-link
        >
        <router-link class="block lg:hidden text-[14px]" to="/affiliate"
          >Become an Affiliate</router-link
        >
        <a
          class="text-[14px]"
          target="_blank"
          rel="nofollow noopener noreferrer"
          :href="`https://${redirectDomainName}/report-content`"
          >Report Content</a
        >
      </div>
    </div>
  </footer>
</template>

<style scoped></style>
