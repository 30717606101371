export default [
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 19,
      name: 'Catalina',
      premadeId: 'e0a83850-1baa-44d0-8361-cc72d902f2e8',
    },
    phrase: 'Care to join me for a shower?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 23,
      name: 'Rina',
      premadeId: '4d0620e2-3212-4489-917d-6510f9b73b52',
    },
    phrase: 'I could use some company...',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 23,
      name: 'Grace',
      premadeId: '288f72ea-71c8-4da2-b4a0-80f7f679dd04',
    },
    phrase: 'Yeeehawww!',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 18,
      name: 'Natasha',
      premadeId: '3758a547-5f92-49d7-a477-6dc18d823644',
    },
    phrase: 'Think you can handle me? Prove it',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 29,
      name: 'Mariana',
      premadeId: '2cd058d3-2a27-4f23-927f-0c0e9841065c',
    },
    phrase: "I'll turn your life into a movie",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 28,
      name: 'Bianca',
      premadeId: 'eac1345b-b206-4910-9295-625efa96a2c7',
    },
    phrase: 'Ready for a tropical adventure?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 25,
      name: 'Sofia',
      premadeId: '24230ab2-ee56-44f5-9d3e-115c1067c4c4',
    },
    phrase: "I'm yours to command",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 19,
      name: 'Ariana',
      premadeId: '3f1facb2-5467-434e-861f-d80d292ccc40',
    },
    phrase: "Please don't body shame me",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 25,
      name: 'Savannah',
      premadeId: '0350e582-a0ca-4ab6-a830-25c0c1884450',
    },
    phrase: 'Come relax with me',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 28,
      name: 'Amara',
      premadeId: 'de447881-3921-48c7-b438-f9ef7fa88db3',
    },
    phrase: 'Will you drive me home?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 20,
      name: 'Alexa',
      premadeId: '27f6e299-7607-495a-b2c7-7aa53bb313ef',
    },
    phrase: 'Make me feel priceless',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 21,
      name: 'Jessica',
      premadeId: '0110ecf7-55ce-449b-8add-3aa4df346d6e',
    },
    phrase: 'Need you on this cozy balcony',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 47,
      name: 'Rebecca',
      premadeId: '3a237262-8888-46ef-ae14-698429e73917',
    },
    phrase: "I'll teach lessons you won't forget",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 26,
      name: 'Mia',
      premadeId: '0427dc77-ac37-4aa4-98b4-c1d65b2bdca5',
    },
    phrase: 'Game on, baby',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 32,
      name: 'Aaliyah',
      premadeId: 'f9e2b1ff-5808-4682-9012-1f00d21edd4a',
    },
    phrase: 'In search of my Sheikh...',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 31,
      name: 'Vanessa',
      premadeId: 'b03b3b7a-7b5d-4ef3-b9e3-e58b81421c36',
    },
    phrase: 'Ready for a game of chance?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 36,
      name: 'Celeste',
      premadeId: '6b204d32-733f-46df-872d-24dc5bca1e31',
    },
    phrase: 'Step inside and let me clean you up',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 26,
      name: 'Christina',
      premadeId: 'b395a243-88fa-42ad-a388-78ee15c69ad1',
    },
    phrase: 'Ready to spoil me today?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 22,
      name: 'Nari',
      premadeId: '587d4b46-dca3-438c-ba03-d00a23b75741',
    },
    phrase: 'Fancy a chat with a view?',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 18,
      name: 'Aria',
      premadeId: 'a4da945b-ad64-4958-8c05-d43ee5641c56',
    },
    phrase: 'Ready to make a splash with me?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 22,
      name: 'Yuki',
      premadeId: '76ac7a20-73e1-43e8-865e-34af58b913a7',
    },
    phrase: 'Konichiwaaa!',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 25,
      name: 'Riley',
      premadeId: 'e264b995-db98-4efd-bb26-e51296dded1e',
    },
    phrase: 'Let me take you for a ride',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 49,
      name: 'Emily',
      premadeId: '62d87ffe-c1e3-41ab-b792-f3a4dce4302e',
    },
    phrase: 'Want to try out my dishes?',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 29,
      name: 'Nori',
      premadeId: '45b6b5bb-63a7-4e86-bcc8-223dbee0e0c4',
    },
    phrase: "Stay close, it's our night!",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 22,
      name: 'Maya',
      premadeId: '4e71f58b-0058-4314-a8ee-50da79d92b8c',
    },
    phrase: "Let's have some fun, come on",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 30,
      name: 'Serena',
      premadeId: 'c11ee9ef-4967-490a-ae99-5aa772718ce6',
    },
    phrase: 'Dare to get closer?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 27,
      name: 'Eva',
      premadeId: '1eb4180f-0bb4-4fe4-9ee1-d1b168183562',
    },
    phrase: 'Wanna race?',
    hidden: true,
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 29,
      name: 'Lilith',
      premadeId: '92a99387-82a6-491a-956a-79dcc1ae0b5f',
    },
    phrase: 'Can you handle my wild side?',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 28,
      name: 'Faye',
      premadeId: 'fb8c7b4f-1df5-465f-b2ef-04588486b9a6',
    },
    phrase: "Let's continue this lesson in private",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 47,
      name: 'Melissa',
      premadeId: 'a7eaa720-3d26-4c49-8812-2c1fafa8a3f8',
    },
    phrase: 'Young men need to impress me',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 29,
      name: 'Miku',
      premadeId: '50bf9552-00eb-4a44-9947-754e3c05cbda',
    },
    phrase: "Surf's up, join me!",
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 27,
      name: 'Ami',
      premadeId: '9cb80cd5-7d04-4c8a-a4fd-ec9862d45a93',
    },
    phrase: "Let's make this sofa our stage",
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 27,
      name: 'Tyler',
      premadeId: 'ca0fc480-856b-4112-afb4-d656b08c6590',
    },
    phrase: "Let's have a blast",
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 30,
      name: 'Diya',
      premadeId: 'bb4e8c5a-1a84-4d04-90d3-65b5b83f5418',
    },
    phrase: 'Be the father my baby deserves',
    hidden: true,
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 40,
      name: 'Layla',
      premadeId: '610a9f77-850f-4b11-80fc-60654cdabb70',
    },
    phrase: 'Care to share a smoke?',
    hidden: true,
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 36,
      name: 'Jennifer',
      premadeId: 'b5515077-4a6a-4144-b115-99de907730bf',
    },
    phrase: 'Come and see me handle business',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 35,
      name: 'Tatiana',
      premadeId: '167cf210-0a1c-4d7a-8f08-d3ac00cc2571',
    },
    phrase: 'Step into my office, honey',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 28,
      name: 'Emi',
      premadeId: 'b45718b9-bf38-4e04-b71f-427065029793',
    },
    phrase: "Lead with love, I'll obey",
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 28,
      name: 'Akari',
      premadeId: 'b2fa937a-e585-4796-821d-191e34fd8b5d',
    },
    phrase: 'Rocking snowy vibes!',
  },
  {
    model: {
      style: 'realistic',
      gender: 'female',
      age: 29,
      name: 'Isabella',
      premadeId: '8f3cac6d-ec98-4ed3-90b4-b49a5f5645e2',
    },
    phrase: "Stop me from saying 'I do'",
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 18,
      name: 'Sienna',
      premadeId: 'cb8becb0-b583-40b1-8fa7-01650275be20',
    },
    phrase: 'Impress me, and keep up',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 23,
      name: 'Suzu',
      premadeId: 'a38ad798-c16d-44a4-bb9c-87e3a7ba9bbb',
    },
    phrase: 'Discover hidden secrets with me',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 24,
      name: 'Seraphina',
      premadeId: 'a49ff470-08b6-4601-9163-bdbefffab919',
    },
    phrase: 'Join me for a quiet read?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 18,
      name: 'Kenji',
      premadeId: 'da431549-2337-4b5a-a3ef-91b77120ce31',
    },
    phrase: 'Ready to explore my world?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 42,
      name: 'Javier',
      premadeId: 'd36d4bb0-a365-4148-ba55-3c50f9d1d755',
    },
    phrase: 'Come and make it special',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 33,
      name: 'Justin',
      premadeId: '1c29fd91-150c-450d-a931-0bd30a234446',
    },
    phrase: "Uncover a lawyer's secret",
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 18,
      name: 'Ryo',
      premadeId: '5d9cff6d-ee89-432f-9851-42f27fc4fc84',
    },
    phrase: 'Be my player two!',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 28,
      name: 'Sean',
      premadeId: 'f283326f-6eac-4b98-9d42-12b09895393c',
    },
    phrase: 'Ready for a night of partying?',
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 33,
      name: 'Hiroshi',
      premadeId: '009270d1-3643-4ba6-92d9-1ffda59f079c',
    },
    phrase: "Let's make every deal exciting",
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 23,
      name: 'Elric',
      premadeId: '971c15be-ad8d-4df0-9494-2023275b8b9b',
    },
    phrase: 'Come fly with me!',
  },
  {
    model: {
      style: 'anime',
      gender: 'female',
      age: 20,
      name: 'Ayumi',
      premadeId: '1c9cb86e-78e6-4f7d-954b-ca4f32406114',
    },
    phrase: 'Seeking a partner in art',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 32,
      name: 'Leonardo',
      premadeId: 'e4e679e3-8e1b-4f54-9820-12bb92fe9133',
    },
    phrase: 'This golf course is all ours today',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 33,
      name: 'Jordan',
      premadeId: 'fe35fb2d-0776-4be4-83ce-364a6e087d45',
    },
    phrase: 'Looking for my basketball partner',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 52,
      name: 'Amir',
      premadeId: 'aa113837-a711-44fc-ac4e-74230e6d0687',
    },
    phrase: "Let's set our hearts on fire",
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 23,
      name: 'Ryuji',
      premadeId: '18a22137-f56a-49b3-a81d-1212d1065019',
    },
    phrase: 'Ready to push your limits with me?',
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 27,
      name: 'Ash',
      premadeId: '73a83a99-5731-4032-a999-d7bb4ce8ae08',
    },
    phrase: 'Ready to enjoy a meal with a twist?',
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 31,
      name: 'Kai',
      premadeId: '0d26c3cb-9ef3-444a-9e45-5584c81485e1',
    },
    phrase: 'Ready for a sunny adventure?',
  },
  {
    model: {
      style: 'realistic',
      gender: 'male',
      age: 33,
      name: 'Logan',
      premadeId: '0e78997e-bfa3-4bdf-a579-663660c0c9ef',
    },
    phrase: 'Join me for a snowy escape',
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 29,
      name: 'Daichi',
      premadeId: 'bfd6efce-c37f-41ee-a9ed-5d87437692a4',
    },
    phrase: "Let's make every check-up exciting",
  },
  {
    model: {
      style: 'anime',
      gender: 'male',
      age: 23,
      name: 'Eren',
      premadeId: '16d37473-919c-40cd-9da8-4c64893dddda',
    },
    phrase: 'Join me for tunes and thrills',
  },
]
