export default function getClientData() {
  return {
    javaEnabled: navigator.javaEnabled && navigator.javaEnabled(),
    language: navigator.language,
    screenHeight: screen.height,
    screenWidth: screen.width,
    screenColorDepth: screen.colorDepth,
    timezone: new Date().getTimezoneOffset(),
  }
}
